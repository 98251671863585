import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import JoinGlassHiveCallout from '../../components/JoinGlassHiveCallout';
import Seo from '../../components/Seo';
import { Container, Row, Col } from 'react-bootstrap';
import { Waypoint } from 'react-waypoint';
import './style.scss';

const IndexPage = () => {
    useEffect(() => {});

    return (
        <Layout>
            <Seo title="Legendary Sales" />
            <div className="sales-page">
                <Container className="hero-container pink-blob">
                    <Row>
                        <Col className="mobile-only">
                            <img
                                src={require('../../images/sales/Group 7100.png')}
                                alt="mobile analytics chart"
                            />
                        </Col>
                        <Col className="one-half max-width-550 z-index-999">
                            <h1>Deliver a Legendary Sales Performance</h1>
                            <p className="hero-subtext">
                                With a CRM that helps you close
                            </p>
                        </Col>
                        <Waypoint
                            onEnter={() => {
                                document
                                    .querySelector('.slide-in-container')
                                    .classList.add('animate');
                            }}
                        />
                        <Col className="one-half">
                            <div className="slide-in-container">
                                <img
                                    className="slide-1"
                                    src={require('../../images/sales/Group 5999.png')}
                                    alt="opportunities pie chart"
                                />
                                <img
                                    className="slide-2"
                                    src={require('../../images/sales/Group 467.png')}
                                    alt="blue gradient background with opportunities total"
                                />
                                <img
                                    className="slide-3"
                                    src={require('../../images/sales/Group 4085.png')}
                                    alt="white background with calls summary"
                                />
                                <img
                                    className="slide-4"
                                    src={require('../../images/sales/Group 5014.png')}
                                    alt="white background with monthly lead quota"
                                />
                                <img
                                    className="slide-5"
                                    src={require('../../images/sales/Group 466.png')}
                                    alt="red gradient background with lead stats"
                                />
                                <img
                                    className="slide-6"
                                    src={require('../../images/sales/Group 4086.png')}
                                    alt="white background with notes summary"
                                />
                                <img
                                    className="slide-7"
                                    src={require('../../images/sales/Group 2495.png')}
                                    alt="purple gradient background with total revenue impact"
                                />
                                <img
                                    className="slide-8"
                                    src={require('../../images/sales/Group 469.png')}
                                    alt="green gradient background with total wins"
                                />
                                <img
                                    className="slide-9"
                                    src={require('../../images/sales/Group 4083.png')}
                                    alt="white background with email summary"
                                />
                                <img
                                    className="slide-10"
                                    src={require('../../images/sales/Group 5817.png')}
                                    alt="search bar and quick add button"
                                />
                                <img
                                    className="slide-11"
                                    src={require('../../images/sales/Group 4087.png')}
                                    alt="white background with meeting summary"
                                />
                                <img
                                    className="slide-12"
                                    src={require('../../images/sales/Group 468.png')}
                                    alt="orange gradient background with total meetings"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="grey-swish-background container-2 centered-text z-index-999">
                    <Row>
                        <Col className="max-width-900 margin-auto">
                            <h3>Features to shatter your quotas</h3>
                            <p className="large-p-text wrapLineSib">
                                GlassHive gives your sales team the tools they
                                need to carry out effective activities without
                                the rigamarole of data entry and file
                                management. When your sales process lives in
                                GlassHive, you can harness the full power of AI,
                                big data, automation, and an integrated
                                collateral library, without sacrificing your
                                workflow.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3 z-index-999">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('#track-activities-video')
                                .play();
                        }}
                    />
                    <Row className="track-activities-row flexed-mobile">
                        <Col className="one-half max-width-500 centered-mobile">
                            <h3>
                                Keep track of activities on and off platform
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                GlassHive has outstanding integrations with
                                Office 365 and Exchange that improve your
                                quality of life.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                Anytime you use Outlook, Teams, or your mobile
                                device to send out an email or schedule a
                                meeting, the activity is automatically logged
                                into GlassHive and connected to the right the
                                contact.
                            </p>
                        </Col>
                        <Col className="one-half centered-text second-col">
                            <video
                                src={require('../../videos/track-activities.mp4')}
                                muted
                                playsInline
                                autoPlay
                                className="track-activities-video hide-on-mobile"
                                id="track-activities-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile"
                                src={require('../../images/sales/The Sceens - Perspective PSD Mockup - by Tranmautritam_2.png')}
                                alt="screen"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-4 z-index-999">
                    <Row>
                        <Waypoint
                            onEnter={() => {
                                document.querySelector('#grunt-work').play();
                            }}
                        />
                        <Col className="one-half">
                            <video
                                src={require('../../videos/ai-gruntwork.mp4')}
                                muted
                                playsInline
                                className="hide-on-mobile"
                                id="grunt-work"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                src={require('../../images/sales/Group 5996.png')}
                                alt="contact profile created by scrubbing the web"
                                className="mobile-img"
                            />
                        </Col>
                        <Col className="one-half second-col max-width-420">
                            <h3 className="centered-mobile">
                                Conduct contact research with a click
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                Finding important lead information is as simple
                                as entering the information on a business card.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                GlassHive automatically scrubs google and social
                                media platforms to create a profile for every
                                lead entered into the platform.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-5">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('#prospect-journey-video')
                                .play();
                        }}
                    />
                    <Row className="prospect-journey-row flexed-mobile">
                        <Col className="one-half max-width-500 margin-left-10-perc">
                            <h3 className="centered-mobile">
                                Automate the prospect journey
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                Doing the same campaigns over and over manually
                                is, frankly, a waste of your time and effort.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                In GlassHive you have the capability to create
                                completely automated email journeys. As simple
                                as selecting a trigger event, a series of
                                campaigns and a timeline, you can send out
                                effective campaigns with little to no exertion
                                on your end. That’s scalability.
                            </p>
                        </Col>
                        <Col className="one-half centered-text second-col">
                            <video
                                src={require('../../videos/prospect-journey.mp4')}
                                muted
                                playsInline
                                autoPlay
                                className="prospect-journey-video hide-on-mobile"
                                id="prospect-journey-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile margin-bottom-125-neg scale-up"
                                src={require('../../images/sales/Group 7106.png')}
                                alt="add collateral to emails"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-6">
                    <Row>
                        <Waypoint
                            onEnter={() => {
                                document.querySelector('#leads-video').play();
                            }}
                        />
                        <Col className="one-half centered-text">
                            <video
                                src={require('../../videos/anim-leads.mp4')}
                                muted
                                playsInline
                                className="hide-on-mobile"
                                id="leads-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                src={require('../../images/sales/Group 6591.png')}
                                className="mobile-img"
                                alt="leads table"
                            />
                        </Col>
                        <Col className="one-half max-width-480 second-col">
                            <h3 className="centered-mobile">
                                Prioritize your leads any way you want
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                Plan your daily activities at a glance.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                GlassHive allows you to organize your leads from
                                temperature all the way to the last activity so
                                you can set your priorities quickly and
                                effectively.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-7">
                    <Row className="flexed-mobile">
                        <Col className="one-half max-width-500 margin-left-10-perc">
                            <h3 className="centered-mobile">
                                A fully-stocked marketing library
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                Every GlassHive subscription comes with access
                                to an ever-growing library of MSP content that
                                you can instantly use in your marketing.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                Whitepapers, eBooks, infographics and more are
                                immediately accessible to your team to be easily
                                inserted into any email. Each content piece has
                                been tried-and-tested to generate the right
                                leads for MSPs like yours.
                            </p>
                        </Col>
                        <Waypoint
                            onEnter={() => {
                                document.querySelector('#library').play();
                            }}
                        />
                        <Col className="one-half centered-text second-col">
                            <video
                                src={require('../../videos/marketing-library.mp4')}
                                muted
                                playsInline
                                className="hide-on-mobile"
                                id="library"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                src={require('../../images/sales/Group 7109.png')}
                                alt="collateral files"
                                className="mobile-img"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-8">
                    <Row className="flexed-mobile">
                        <Col className="one-half max-width-500 margin-left-10-perc">
                            <h3 className="centered-mobile">
                                Benchmark yourself against the best
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                Big data means big improvements. Dial in your
                                sales process and KPIs with big data
                                automatically gathered and compiled within
                                GlassHive.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                GlassHive tracks all of your sales and marketing
                                statistics, and even allows you to compare them
                                against best-in-class performers in the
                                industry.
                            </p>
                        </Col>
                        <Waypoint
                            onEnter={() => {
                                document
                                    .querySelector('.area-chart')
                                    .classList.add('animate');
                            }}
                        />
                        <Col className="one-half centered-text rel second-col width-55-perc">
                            <img
                                className="margin-bottom-50-neg"
                                src={require('../../images/sales/Group 7210.png')}
                                alt="monthly summary of emails,calls,meetings,and opportunties"
                            />
                            <img
                                className="area-chart"
                                src={require('../../images/sales/Group 7208.png')}
                                alt="pink gradient curvy background"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-9">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('#great-visibility-video')
                                .play();
                        }}
                    />
                    <Row className="great-visibility-row flexed-mobile">
                        <Col className="one-half max-width-500 margin-left-10-perc">
                            <h3 className="centered-mobile">
                                Notifications that provide great visibility
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                GlassHive’s notifications are like a great sales
                                assistant. You will instantly know when leads
                                have been assigned to you, the exact moment they
                                are showing interest, and the whether some leads
                                are getting cold.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                GlassHive also rings the sales bell for you by
                                letting everyone know when you have an
                                opportunity, close a sale, and hit your quota.
                            </p>
                        </Col>
                        <Col className="one-half centered-text second-col">
                            <video
                                src={require('../../videos/great-visibility-gh.mp4')}
                                muted
                                playsInline
                                autoPlay
                                className="great-visibility-video hide-on-mobile"
                                id="great-visibility-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile margin-bottom-125-neg"
                                src={require('../../images/sales/Group 7112.png')}
                                alt="email notifications"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-10">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('.join-glasshive-callout')
                                .classList.add('animate');
                        }}
                    />
                    <Row>
                        <Col className="three-fourth">
                            <JoinGlassHiveCallout />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default IndexPage;
